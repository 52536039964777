import React, { RefObject, useEffect } from "react";
import { connect } from "react-redux";
import { setUser } from "../../state/creators";
import ContentLayout from "../../components/layout/contentLayout";
import About from "../../components/content/about";
import withAuth from "../_withAuth";
import { MeInterface, UserInterface } from "../../_types/user";
import Head from "next/head";
import { useRef } from "react";

type Props = {
	me: MeInterface;
	setUser: (user: UserInterface | null) => void;
};

const AboutPage = ({ me, setUser }: Props) => {
	useEffect(() => {
		if (!me) {
			setUser(null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const aboutRef: RefObject<HTMLDivElement> = useRef(null);

	const handleScrollToTarget = () => {
		if (aboutRef.current) {
			aboutRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<>
			<Head>
				<link rel="canonical" href="https://www.helloedun.co.uk/about" />
			</Head>
			<ContentLayout auth={me ? true : false} handleScrollToTarget={handleScrollToTarget}>
				<About ref={aboutRef} />
			</ContentLayout>
		</>
	);
};

export const getServerSideProps = withAuth(
	async (ctx: any, me: any) => {
		return {
			props: {
				me: me || null,
			},
		};
	},
	null,
	null,
	true
);

const mapDispatchToProps = {
	setUser,
};

export default connect(null, mapDispatchToProps)(AboutPage);
