import { useEffect, useRef, useState } from "react";
import { VideoWrapper } from "../../styles/blocks";

export const VideoPlayer = () => {
	const videoContainer = useRef<HTMLDivElement>(null);
	const video = useRef<HTMLVideoElement>(null);

	const [displayControls, setDisplayControls] = useState<boolean>(true);
	const [playing, setPlaying] = useState<boolean>(true);
	const [muted, setMuted] = useState<boolean>(true);

	useEffect(() => {
		const supportsVideo = !!document.createElement("video").canPlayType;
		if (!supportsVideo) {
			setDisplayControls(false);
		}
	}, []);

	const playPause = () => {
		if (video.current) {
			if (video.current.paused) {
				video.current.play();
				setPlaying(true);
			} else {
				video.current.pause();
				setPlaying(false);
			}
		}
	};

	const muteUnmute = () => {
		if (video.current) {
			if (video.current.muted) {
				video.current.muted = false;
				setMuted(false);
			} else {
				video.current.muted = true;
				setMuted(true);
			}
		}
	};

	const restart = () => {
		if (video.current) {
			video.current.currentTime = 0;
			video.current.play();
		}
	};

	return (
		<VideoWrapper>
			<figure id="videoContainer" ref={videoContainer}>
				<video id="video" autoPlay loop muted preload="metadata" poster="/static/images/about-1-dashboard.png" ref={video}>
					<source src="https://storage.googleapis.com/edun-static/videos/edun_crm_the_professional_network_in_education.mp4" type="video/mp4" />
					<source src="https://storage.googleapis.com/edun-static/videos/edun_crm_the_professional_network_in_education.webm" type="video/webm" />
				</video>
				{displayControls ? (
					<div id="controls">
						<button type="button" onClick={muteUnmute}>
							<i>
								{muted ? (
									<svg viewBox="0 0 512 512">
										<path
											d="m89.752 59.582 251.583 251.583 5.433 5.432 49.472 49.474v-.001l30.862 30.86h-.001l25.317 25.318-30.17 30.17-187.832-187.833v164.103l-110.729-85.459h-81.02V172.563h80.895l10.538-8.293-74.518-74.518 30.17-30.17Zm314.213 28.014c67.74 75.64 82.499 181.38 44.28 270.137l-32.95-32.95c23.87-71.004 8.998-151.973-44.615-210.559l33.285-26.628Zm-84.385 67.51c28.626 31.924 41.555 72.769 38.788 112.752l-49.237-49.236c-4.823-12.915-12.148-25.121-21.976-35.884l-.9-.974 33.325-26.659Zm-85.163-69.773-.001 58.574-32.78-32.78 32.78-25.794Z"
											fill="currentColor"
											fillRule="evenodd"
										/>
									</svg>
								) : (
									<svg viewBox="0 0 512 512">
										<path
											d="m403.966 426.944-33.285-26.629c74.193-81.076 74.193-205.016-.001-286.091l33.285-26.628c86.612 96.713 86.61 242.636.001 339.348ZM319.58 155.105l-33.325 26.66c39.796 42.567 39.795 108.443.002 151.01l33.324 26.66c52.204-58.222 52.204-146.11-.001-204.33Zm-85.163-69.772-110.855 87.23H42.667v170.666h81.02l110.73 85.459V85.333ZM85.333 215.23h53.021l53.396-42.02v168.645l-53.52-41.291H85.332v-85.334Z"
											fill="currentColor"
											fillRule="evenodd"
										/>
									</svg>
								)}
							</i>
						</button>
						<button type="button" onClick={playPause}>
							<i>
								{playing ? (
									<svg viewBox="0 0 24 24" fill="none">
										<path d="M14 9v6m-4-6v6m2 6a9 9 0 1 1 0-18 9 9 0 0 1 0 18Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								) : (
									<svg viewBox="-0.5 0 7 7">
										<path
											d="M5.495 2.573 1.5.143C.832-.266 0 .25 0 1.068V5.93c0 .82.832 1.333 1.5.927l3.995-2.43c.673-.41.673-1.445 0-1.855"
											fill="currentColor"
											fillRule="evenodd"
										/>
									</svg>
								)}
							</i>
						</button>
						<button type="button" onClick={restart}>
							<i>
								<svg viewBox="0 0 24 24" fill="none">
									<path d="M12 3a9 9 0 1 1-5.657 2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
									<path d="M3 4.5h4v4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
								</svg>
							</i>
						</button>
					</div>
				) : null}
			</figure>
		</VideoWrapper>
	);
};
